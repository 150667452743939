import {
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './institutionalFirm.constants';
import Checkbox from '../../../../components/FormComponents/Checkbox';

const InstitutionalFirm = ({ formRef, handleSubmit }) => {
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={schema}
			validateOnBlur={true}
			innerRef={formRef}
		>
			{({ isSubmitting, setFieldValue, values, touched, errors }) => (
				<Form autoComplete="off" style={{ width: '100%' }}>
					<Stack spacing={4}>
						<Stack spacing={2}>
							<Typography color={'text.primary'}>
								Regulations require that private market investors satisfy accreditation requirements by making one of
								the following representations regarding net worth, status or designations and certain related matters.
							</Typography>
							<Typography color={'text.primary'}>
								Please check one of the following applicable representation’s regarding net worth and certain related
								matters.
							</Typography>
						</Stack>
						<Divider variant={'fullWidth'} />
						<Stack spacing={'20px'}>
							<FormControl error={touched.trust && Boolean(errors.trust)}>
								<FormLabel>
									A trust with total assets in excess of $5,000,000 whose purchase is directed by a person with such
									knowledge and experience in financial and business matters that such person is capable of evaluating
									the merits and risks of the prospective investment.
								</FormLabel>
								<RadioGroup
									required
									name="trust"
									value={values.trust}
									onChange={(event) => {
										setFieldValue('trust', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.trust && errors.trust && <FormHelperText>{errors.trust}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.bank && Boolean(errors.bank)}>
								<FormLabel>
									A bank, an investment adviser registered pursuant to Section 203 of the Advisers Act or registered
									pursuant to the laws of a state, any investment adviser relying on the exemption from registering with
									the SEC under Section 203(l) or (m) of the Advisers Act, an insurance company, an investment company
									registered under the United States Investment Company Act of 1940, as amended, a broker or dealer
									registered pursuant to Section 15 of the United States Securities Exchange Act of 1934, as amended, a
									business development company, a Small Business Investment Company licensed by the United States Small
									Business Administration, a Rural Business Investment Company as defined in Section 384A of the
									Consolidated Farm and Rural Development Act, as amended, a plan with total assets in excess of
									$5,000,000 established and maintained by a state for the benefit of its employees, or a private
									business development company as defined in Section 202(a)(22) of the Advisers Act.
								</FormLabel>
								<RadioGroup
									required
									name="bank"
									value={values.bank}
									onChange={(event) => {
										setFieldValue('bank', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.bank && errors.bank && <FormHelperText>{errors.bank}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.employeeBenefit && Boolean(errors.employeeBenefit)}>
								<FormLabel>
									An employee benefit plan and either all investment decisions are made by a bank, savings and loan
									association, insurance company, or registered investment advisor, or the undersigned has total assets
									in excess of $5,000,000 or, if such plan is a self-directed plan, investment decisions are made solely
									by persons who are accredited investors.
								</FormLabel>
								<RadioGroup
									required
									name="employeeBenefit"
									value={values.employeeBenefit}
									onChange={(event) => {
										setFieldValue('employeeBenefit', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.minIncome && errors.minIncome && <FormHelperText>{errors.minIncome}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.corporationLLC && Boolean(errors.corporationLLC)}>
								<FormLabel>
									A corporation, limited liability company, partnership, business trust, not formed for the purpose of
									acquiring the Securities, or an organization described in Section 501(c)(3) of the Internal Revenue
									Code of 1986, as amended (the “Code”), in each case with total assets in excess of $5,000,000.
								</FormLabel>
								<RadioGroup
									required
									name="corporationLLC"
									value={values.corporationLLC}
									onChange={(event) => {
										setFieldValue('corporationLLC', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.corporationLLC && errors.corporationLLC && (
									<FormHelperText>{errors.corporationLLC}</FormHelperText>
								)}
							</FormControl>

							<FormControl error={touched.minIncome && Boolean(errors.minIncome)}>
								<FormLabel>
									An entity in which all of the equity owners (in the case of a revocable living trust, its grantor(s))
									qualify under any of the above subparagraphs, or, if an individual, each such individual has a net
									worth,2 either individually or upon a joint basis with such individual’s spouse or spousal equivalent,
									as applicable, in excess of $1,000,000 (within the meaning of such terms as used in the definition of
									“accredited investor” contained in Rule 501 under the Act), or has had an individual income1 in excess
									of $200,000 for each of the two most recent years, or a joint income with such individual’s spouse or
									spousal equivalent, as applicable, in excess of $300,000 in each of those years, and has a reasonable
									expectation of reaching the same income level in the current year.
								</FormLabel>
								<RadioGroup
									required
									name="minIncome"
									value={values.minIncome}
									onChange={(event) => {
										setFieldValue('minIncome', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.minIncome && errors.minIncome && <FormHelperText>{errors.minIncome}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.investmentExcessFive && Boolean(errors.investmentExcessFive)}>
								<FormLabel>
									An entity, of a type not listed in any of the paragraphs above, which was not formed for the specific
									purpose of acquiring the securities offered, owning investments in excess of $5,000,000.
								</FormLabel>
								<RadioGroup
									required
									name="investmentExcessFive"
									value={values.investmentExcessFive}
									onChange={(event) => {
										setFieldValue('investmentExcessFive', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.investmentExcessFive && errors.investmentExcessFive && (
									<FormHelperText>{errors.investmentExcessFive}</FormHelperText>
								)}
							</FormControl>

							<FormControl error={touched.familyOffice && Boolean(errors.familyOffice)}>
								<FormLabel>
									A “family office,” as defined in rule 202(a)(11)(G)-1 under the Advisers Act, (i) with assets under
									management in excess of $5,000,000, (ii) that is not formed for the specific purpose of acquiring the
									securities offered, and (iii) whose prospective investment is directed by a person who has such
									knowledge and experience in financial and business matters that such family office is capable of
									evaluating the merits and risks of the prospective investment.
								</FormLabel>
								<RadioGroup
									required
									name="familyOffice"
									value={values.familyOffice}
									onChange={(event) => {
										setFieldValue('familyOffice', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.familyOffice && errors.familyOffice && <FormHelperText>{errors.familyOffice}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.familyClient && Boolean(errors.familyClient)}>
								<FormLabel>
									A “family client,” as defined in rule 202(a)(11)(G)-1 under the Advisers Act, of a family office
									meeting the requirements in the above paragraph and whose prospective investment is directed by such
									family office pursuant to clause (iii) of the above paragraph.
								</FormLabel>
								<RadioGroup
									required
									name="familyClient"
									value={values.familyClient}
									onChange={(event) => {
										setFieldValue('familyClient', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.familyClient && errors.familyClient && <FormHelperText>{errors.v}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.representations && Boolean(errors.representations)}>
								<Checkbox
									name="representations"
									label="None of the above applies to me/my firm."
									checked={values.representations}
									onChange={(e) => {
										setFieldValue('representations', e.currentTarget.checked);
									}}
									disabled={isSubmitting}
								/>
							</FormControl>
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default InstitutionalFirm;
