import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { IRetailAccreditationQuestionnaireForm } from '../../formConstants';

const IntroStep: React.FC = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { values, setFieldValue } = useFormikContext<IRetailAccreditationQuestionnaireForm>();

	return (
		<Stack direction={'column'} alignItems={'center'}>
			<Stack spacing={5} direction={'column'} alignItems={'center'}>
				<Typography variant={'h5'} textAlign={'center'}>
					Accreditation Questionnaire
				</Typography>
				<Typography variant={'body1'}>
					Regulations require that private market participants satisfy accreditation requirements by making one of the
					following representations regarding income, net worth, status, and/or certain professional certifications or
					designations and certain related matters. <br />
					<br />
					It will take less than a minute to complete.
				</Typography>
				<Button
					variant={'contained'}
					size={'large'}
					fullWidth={isMobile}
					endIcon={<ArrowForward />}
					onClick={() => setFieldValue('step', values.step + 1)}
				>
					Start
				</Button>
			</Stack>
		</Stack>
	);
};

export default IntroStep;
