import { Box, Button, Fade, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { IRetailAccreditationQuestionnaireForm } from '../../formConstants';
import { useNavigate } from 'react-router-dom';

const FinalStep: React.FC = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { handleSubmit, isSubmitting } = useFormikContext<IRetailAccreditationQuestionnaireForm>();

	useEffect(() => {
		handleSubmit();
	}, []);

	return (
		<Fade in timeout={500}>
			<Stack direction={'column'} alignItems={'center'}>
				<Stack spacing={5} direction={'column'} alignItems={'center'}>
					<Box visibility={isSubmitting ? 'hidden' : 'visible'}>
						<img src={'/illustrations/dark/heart.svg'} style={{ width: 150 }} alt="Thank you" />
					</Box>
					<Typography variant={'h5'} textAlign={'center'}>
						{isSubmitting ? 'Saving answers...' : 'Thank you for submitting the accreditation form.'}
					</Typography>
					<Button
						sx={{ visibility: isSubmitting ? 'hidden' : 'visible' }}
						variant={'contained'}
						size={'large'}
						endIcon={<ArrowForward />}
						onClick={() => navigate('/')}
						fullWidth={isMobile}
					>
						go home
					</Button>
				</Stack>
			</Stack>
		</Fade>
	);
};

export default FinalStep;
