import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
	AppBar as MuiAppBar,
	Avatar,
	Badge,
	Box,
	Container,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	SwipeableDrawer,
	Tab,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useActivityFeedContext, useAuthContext, useNotificationContext } from '../../context';
import Logo from '../Logo';
import md5 from 'md5';
import { Notifications } from '@mui/icons-material';
import { TabsWrapper } from './style';
import MenuIcon from '@mui/icons-material/Menu';

const AppBar = () => {
	const location = useLocation();
	const pathValue = location.pathname;
	const defaultTheme = useTheme();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
	const { user, logout, inquiries, isDataLoading } = useAuthContext();
	const { intercomBadge, dealChatsBadge } = useNotificationContext();
	const { setOpen: setActivityFeedOpen, feed } = useActivityFeedContext();
	const [value, setValue] = useState('/home');
	const [isMobileMenuDrawerOpen, setMobileMenuDrawerOpen] = useState(false);

	const dashboardBadgeCounter = useMemo(() => {
		return (
			inquiries &&
			inquiries.length &&
			inquiries.reduce((badgeItems, inquiry) => {
				const foundLogs = [];
				const questionnaires = ['OnboardingPersonal', 'OnboardingTransactional'];
				inquiry.activityLogs?.forEach((activityLog) => {
					if (questionnaires.includes(activityLog.fields['Type']) && !activityLog.fields['Submitted At']) {
						foundLogs.push(activityLog);
					}
				});
				return [...badgeItems, ...foundLogs];
			}, []).length
		);
	}, [inquiries]);

	const tabs = [
		{
			isVisible: user?.isAdmin,
			to: '/admin',
			label: (
				<Badge invisible={!(intercomBadge > 0 || dealChatsBadge > 0)} color="error" variant={'dot'}>
					Admin
				</Badge>
			),
			value: '/admin',
		},
		{
			isVisible: true, // Always render this tab
			to: '/home',
			label: (
				<Badge badgeContent={dashboardBadgeCounter} color="error" sx={{ lineHeight: '24px' }}>
					Home
				</Badge>
			),
			value: '/home',
		},
		{
			isVisible: true, // Always render this tab
			to: '/companies',
			label: 'Companies',
			value: '/companies',
			'data-cy': 'appbar-companies',
		},
		{
			isVisible: user?.instie,
			to: '/report',
			label: 'Report',
			value: '/report',
			'data-cy': 'appbar-inst-report',
		},
		{
			isVisible: !user?.instie,
			to: '/referral',
			label: 'Referral',
			value: '/referral',
		},
		{
			isVisible: user?.isLevel3,
			to: '/market',
			label: 'Market',
			value: '/market',
			'data-cy': 'appbar-inst-market',
		},
		{
			isVisible: user?.instie,
			to: '/settings',
			label: 'Settings',
			value: '/settings',
			'data-cy': 'appbar-inst-settings',
		},
	];

	useEffect(() => {
		setValue(getTabsValue(pathValue));
	}, [pathValue]);

	const handleProfileMenu = (event) => {
		setProfileMenuAnchorEl(event.currentTarget);
	};

	const handleProfileMenuClose = () => {
		setProfileMenuAnchorEl(null);
	};

	function getTabsValue(value) {
		if (value.includes('admin')) return '/admin';
		if (value.includes('home')) return '/home';
		if (value.includes('bid')) return '/home';
		if (value.includes('compan')) return '/companies';
		if (value.includes('referral')) return '/referral';
		if (value.includes('report')) return '/report';
		if (value.includes('setting')) return '/settings';
		if (value.includes('market')) return '/market';

		return '/';
	}

	const handleLogout = () => {
		handleProfileMenuClose();
		logout();
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	if (isDataLoading) return null;

	return (
		<MuiAppBar
			position="sticky"
			sx={{
				background: isMobile ? 'undefined' : defaultTheme.palette.background.default,
				borderBottom: `1px solid ${defaultTheme.palette.divider}`,
				boxShadow: isMobile ? 'undefined' : 'none',
			}}
		>
			<Toolbar sx={{ justifyContent: 'space-between' }}>
				{isMobile && (
					<Box flex={1}>
						<IconButton
							size="large"
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={() => setMobileMenuDrawerOpen((prevState) => !prevState)}
						>
							<Badge badgeContent={dashboardBadgeCounter} color="error">
								<MenuIcon />
							</Badge>
						</IconButton>
					</Box>
				)}
				<Logo color={defaultTheme.palette.text.primary} isShort={true} isAbsolute={false} />
				{!isMobile && (
					<Container sx={{ height: '100%' }} maxWidth={user?.isAdmin ? 'xl' : undefined}>
						<TabsWrapper value={value} onChange={handleChange}>
							{tabs.map((tab, index) =>
								tab.isVisible ? (
									<Tab
										key={index}
										component={Link}
										to={tab.to}
										label={tab.label}
										value={tab.value}
										{...(tab['data-cy'] && { 'data-cy': tab['data-cy'] })}
									/>
								) : null
							)}
						</TabsWrapper>
					</Container>
				)}
				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'flex-end'}
					flex={1}
					spacing={2}
					sx={{ flexGrow: isMobile ? 1 : 0 }}
				>
					<Tooltip title={'Activity Feed'} enterDelay={500}>
						<IconButton
							onClick={(event) => {
								event.stopPropagation();
								setActivityFeedOpen((prev) => !prev);
							}}
							size={isMobile ? 'large' : 'medium'}
						>
							<Badge badgeContent={feed.filter((item) => !item.answered && !item.expired).length} color="error">
								<Notifications />
							</Badge>
						</IconButton>
					</Tooltip>
					<IconButton onClick={handleProfileMenu} sx={{ p: 0 }}>
						<Avatar
							alt={`${user?.fields['First Name']} ${user?.fields['Last Name']}`}
							src={
								user?.fields.Email
									? 'https://www.gravatar.com/avatar/' + md5(user?.fields.Email) + '.jpg?d=404'
									: user?.fields.photo
							}
							sx={{ width: 32, height: 32, fontSize: 16 }}
						>
							{`${user?.fields['First Name'][0]}${user?.fields['Last Name'][0]}`}
						</Avatar>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={profileMenuAnchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(profileMenuAnchorEl)}
						onClose={handleProfileMenuClose}
					>
						<Box pl={2} pr={2} pt={1} pb={'12px'}>
							<Typography variant={'body2'} color={'text.secondary'}>
								{user?.fields['Email']}
							</Typography>
						</Box>
						<Divider />
						{/*{!user?.instie && <MenuItem disabled>Settings</MenuItem>}*/}
						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</Menu>
				</Stack>
			</Toolbar>
			<nav>
				<SwipeableDrawer
					variant="temporary"
					open={isMobile && isMobileMenuDrawerOpen}
					onClose={() => setMobileMenuDrawerOpen(false)}
					onOpen={() => setMobileMenuDrawerOpen(true)}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					PaperProps={{
						elevation: 3,
						sx: {
							boxSizing: 'border-box',
							width: '50%',
							top: 56, // Shift the Drawer down
							backgroundColor: 'background.paper', // Adjust background color based on the theme
						},
					}}
				>
					<List>
						{tabs.map((tab, index) =>
							tab.isVisible ? (
								<ListItem key={index} disablePadding>
									<ListItemButton
										component={Link}
										to={tab.to}
										selected={value === tab.value}
										onClick={() => setMobileMenuDrawerOpen(false)}
									>
										<ListItemText primary={tab.label} />
									</ListItemButton>
								</ListItem>
							) : null
						)}
					</List>
				</SwipeableDrawer>
			</nav>
		</MuiAppBar>
	);
};

export default AppBar;
