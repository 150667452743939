import {
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './accreditedInvestor.constants';
import Checkbox from '../../../../components/FormComponents/Checkbox';

const AccreditedInvestor = ({ formRef, handleSubmit }) => {
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={schema}
			validateOnBlur={true}
			innerRef={formRef}
		>
			{({ isSubmitting, setFieldValue, values, touched, errors }) => (
				<Form autoComplete="off" style={{ width: '100%' }}>
					<Stack spacing={4}>
						<Stack spacing={2}>
							<Typography color={'text.primary'}>
								Regulations require that private market investors satisfy accreditation requirements by making one of
								the following representations regarding its income, net worth, status as a “family client” of a “family
								office,” and/or certain professional certifications or designations and certain related matters.
							</Typography>
							<Typography color={'text.primary'}>
								Please check one of the following applicable representation’s regarding net worth and certain related
								matters.
							</Typography>
						</Stack>
						<Divider variant={'fullWidth'} />
						<Stack spacing={'20px'}>
							<FormControl error={touched.minIncome && Boolean(errors.minIncome)}>
								<FormLabel>
									Income during each of the last two years exceeded $200,000 or, if the undersigned is married or has a
									spousal equivalent , the joint income of the undersigned and the undersigned’s spouse or spousal
									equivalent, as applicable, during each of the last two years exceed $300,000, and the undersigned
									reasonably expects the undersigned’s income, from all sources during this year, will exceed $200,000
									or, if the undersigned is married or has a spousal equivalent, the joint income of undersigned and the
									undersigned’s spouse or spousal equivalent, as applicable, from all sources during this year will
									exceed $300,000.
								</FormLabel>
								<RadioGroup
									required
									name="minIncome"
									value={values.minIncome}
									onChange={(event) => {
										setFieldValue('minIncome', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.minIncome && errors.minIncome && <FormHelperText>{errors.minIncome}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.netWorth && Boolean(errors.netWorth)}>
								<FormLabel>
									Net worth, including the net worth of the undersigned’s spouse or spousal equivalent, as applicable,
									is in excess of $1,000,000 (excluding the value of the undersigned’s primary residence).
								</FormLabel>
								<RadioGroup
									required
									name="netWorth"
									value={values.netWorth}
									onChange={(event) => {
										setFieldValue('netWorth', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.netWorth && errors.netWorth && <FormHelperText>{errors.netWorth}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.goodStanding && Boolean(errors.goodStanding)}>
								<FormLabel>
									A holder in good standing of one or more of the following certifications or designations administered
									by the Financial Industry Regulatory Authority, Inc. (FINRA): the Licensed General Securities
									Representative (Series 7), Licensed Investment Adviser Representative (Series 65), or Licensed Private
									Securities Offerings Representative (Series 82).
								</FormLabel>
								<RadioGroup
									required
									name="goodStanding"
									value={values.goodStanding}
									onChange={(event) => {
										setFieldValue('goodStanding', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.goodStanding && errors.goodStanding && <FormHelperText>{errors.goodStanding}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.familyClient && Boolean(errors.familyClient)}>
								<FormLabel>
									A “family client,” as defined in rule 202(a)(11)(G)-1 under the Investment Advisers Act of 1940, as
									amended (the “Advisers Act”), of a family office as defined in rule 202(a)(11)(G)-1 under the Advisers
									Act, (i) with assets under management in excess of $5,000,000, (ii) that is not formed for the
									specific purpose of acquiring the securities offered, and (iii) whose prospective investment is
									directed by a person who has such knowledge and experience in financial and business matters that such
									family office is capable of evaluating the merits and risks of the prospective investment, and whose
									prospective investment is directed by such family office pursuant to clause (iii) of this sentence.
								</FormLabel>
								<RadioGroup
									required
									name="familyClient"
									value={values.familyClient}
									onChange={(event) => {
										setFieldValue('familyClient', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting} />}
										disabled={isSubmitting}
									/>
								</RadioGroup>
								{touched.familyClient && errors.familyClient && <FormHelperText>{errors.familyClient}</FormHelperText>}
							</FormControl>

							<FormControl error={touched.representations && Boolean(errors.representations)}>
								<Checkbox
									name="representations"
									label="None of the above applies to me/my firm."
									checked={values.representations}
									onChange={(e) => {
										setFieldValue('representations', e.currentTarget.checked);
									}}
									disabled={isSubmitting}
								/>
							</FormControl>
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default AccreditedInvestor;
