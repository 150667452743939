import { Button, Fade, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { IRetailAccreditationQuestionnaireForm } from '../../formConstants';

const NetWorthStep: React.FC = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { values, setFieldValue } = useFormikContext<IRetailAccreditationQuestionnaireForm>();

	return (
		<Fade in timeout={500}>
			<Stack direction={'column'} alignItems={'center'}>
				<Stack spacing={5} direction={'column'} alignItems={'center'}>
					<Typography variant={'h6'} textAlign={'center'}>
						Do you own at least $5 million in investments individually or through an investment entity?
					</Typography>
					<Stack spacing={2} direction={'row'} width={isMobile ? '100%' : undefined}>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Check />}
							onClick={() => {
								setFieldValue('netWorth', 'Yes');
								setFieldValue('step', values.step + 1);
							}}
							fullWidth={isMobile}
						>
							Yes
						</Button>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Close />}
							onClick={() => {
								setFieldValue('netWorth', 'No');
								setFieldValue('step', values.step + 1);
							}}
							fullWidth={isMobile}
						>
							No
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Fade>
	);
};

export default NetWorthStep;
