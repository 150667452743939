import PageTitle from '../../../../components/PageTitle';
import { Box, Button, Dialog, LinearProgress, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAuthContext } from '../../../../context';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../../../utils/strings';
import { EmptyContainer } from '../../../../styles/common.styles';
import IntroStep from './steps/IntroStep';
import { Formik } from 'formik';
import { initialValues } from './formConstants';
import MinIncomeStep from './steps/MinIncomeStep';
import NetWorthStep from './steps/NetWorthStep';
import GoodStandingStep from './steps/GoodStandingStep';
import FinalStep from './steps/FinalStep';
import { ChevronLeft } from '@mui/icons-material';

const RetailAccreditationDialog = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { user, reloadUserData } = useAuthContext();
	const steps = [<IntroStep />, <MinIncomeStep />, <NetWorthStep />, <GoodStandingStep />, <FinalStep />];

	const handleSubmit = async (values) => {
		const payload = JSON.stringify({ accreditation: JSON.stringify({ ...values, step: undefined }) });

		try {
			const res = await apiClient.post(endpoints.submitAccreditationForm, payload, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (res.status === 200) {
				reloadUserData();
			}
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.saveAnswers);
			console.log('handleSubmit.err', err);
		}
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Home',
					},
				]}
				title={'Dashboard'}
			/>

			<EmptyContainer>
				<Stack spacing={3} alignItems={'center'}>
					<img src={'/illustrations/dark/notFound.svg'} style={{ height: 252 }} alt="No trades" />
					<Stack spacing={1} alignItems={'center'}>
						<Typography variant={'h5'}>You have no activity.</Typography>
					</Stack>
				</Stack>
			</EmptyContainer>

			<Dialog
				open={!user?.isAccredited}
				fullWidth
				maxWidth={'md'}
				slotProps={{
					backdrop: {
						sx: {
							backdropFilter: 'blur(6px)',
							backgroundColor: 'rgba(0, 0, 0, 0.50)',
						},
					},
				}}
			>
				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					{({ values, isSubmitting, setFieldValue }) => (
						<Stack
							height={isMobile ? '80svh' : 440}
							maxHeight={isMobile ? 600 : 'undefined'}
							direction={'column'}
							p={2}
							position={'relative'}
						>
							<LinearProgress
								variant="determinate"
								value={(values.step / (steps.length - 1)) * 100}
								color={values.step < steps.length - 1 || isSubmitting ? 'primary' : 'success'}
								sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
							/>
							<Box
								visibility={values.step > 1 ? undefined : 'hidden'}
								display={values.step < steps.length - 1 ? undefined : 'none'}
							>
								<Button
									variant={'text'}
									startIcon={<ChevronLeft />}
									sx={{ color: 'text.secondary' }}
									size={isMobile ? 'large' : 'small'}
									onClick={() => {
										setFieldValue('step', values.step - 1);
									}}
								>
									Back
								</Button>
							</Box>
							<Stack
								pl={isMobile ? 0 : 3}
								pr={isMobile ? 0 : 3}
								flex={1}
								justifyContent={'center'}
								direction={'column'}
							>
								{steps[values.step]}
							</Stack>
							<Typography
								variant="caption"
								color="text.secondary"
								textAlign={'center'}
								display={values.step < steps.length - 1 ? undefined : 'none'}
							>
								SEC definitions for{' '}
								<Link
									href="https://www.sec.gov/resources-small-businesses/capital-raising-building-blocks/accredited-investors"
									target="_blank"
									rel="noopener noreferrer"
								>
									Accredited Investor
								</Link>{' '}
								and{' '}
								<Link
									href="https://www.ecfr.gov/current/title-17/chapter-II/part-227/subpart-E/section-227.504"
									target="_blank"
									rel="noopener noreferrer"
								>
									Qualified Purchaser
								</Link>
								.
							</Typography>
						</Stack>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default RetailAccreditationDialog;
