import { ChatBoxWrapper, FooterPaper, HeaderPaper } from '../../style';
import { Avatar, Badge, Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import ChatBox from './ChatBox';
import ChatControls from './ChatControls';
import React, { useEffect, useState } from 'react';
import { useAudioContext, useChatWidgetContext, useAuthContext } from '../../../../context';
import { apiClient, endpoints } from '../../../../utils';

const ChatTab = () => {
	const { isChatWidgetOpen: open } = useChatWidgetContext();
	const { messages, setMessages } = useChatWidgetContext();
	const { playReceiveSound: playReceiveMessageSound, playSendSound: playSendMessageSound } = useAudioContext();
	const [isFileLoading, setFileLoading] = useState(false);
	const { user } = useAuthContext();

	//counter
	const { newMessages, setNewMessages } = useChatWidgetContext();
	const [hasShownBadgeAndPlayedSound, setHasShownBadgeAndPlayedSound] = useState(false);

	useEffect(() => {
		if (open && newMessages.length) {
			markChatRead();
		}
	}, [open]);

	useEffect(() => {
		if (messages.length) {
			const newMsgs = messages.filter((item) => item.fields.isNew && item.fields['From User'] !== true);
			if (newMsgs.length) {
			}
			if (newMsgs.length && open) {
				markChatRead(newMsgs);
			}

			if (newMsgs.length && !open && !hasShownBadgeAndPlayedSound) {
				setNewMessages(newMsgs);
				playReceiveMessageSound();
				setHasShownBadgeAndPlayedSound(true);
			}
		}
	}, [messages]);

	const markChatRead = async (arr) => {
		const res = await apiClient.post(endpoints.markChatRead, {
			ids: arr ? arr.map((item) => item.id) : newMessages.map((item) => item.id),
		});
		if (res) {
			setNewMessages([]);
			setHasShownBadgeAndPlayedSound(false);
		}
	};

	const uploadFiles = async (files) => {
		const formData = new FormData();

		for (const file of files) {
			if (file instanceof Blob) {
				formData.append('files', file);
			} else {
				const blob = await fetch(file.url).then((r) => r.blob());
				const parts = [blob];
				const newFile = new File(parts, file.filename, { url: file.url, ...file });
				formData.append('files', newFile);
			}
		}

		return await apiClient.post(endpoints.sendChatAttachment, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	const handleSendMessage = async (newMessage, files) => {
		if (newMessage) {
			playSendMessageSound();

			const res = await apiClient.post(endpoints.sendChatMessage, {
				message: newMessage,
			});

			if (res.data) {
				setMessages((prevState) => [...prevState, ...res.data]);
			}
		}

		if (files) {
			setFileLoading(true);
			const res = await uploadFiles(files);

			if (res.data) {
				playSendMessageSound();
				setMessages((prevState) => [...prevState, ...res.data]);
				setFileLoading(false);
			}
		}
	};

	return (
		<>
			<HeaderPaper>
				<Stack direction={'row'} spacing={2} alignItems={'center'}>
					<Stack direction={'column'} spacing={0} alignItems={'center'} position={'relative'}>
						<Badge
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							color="success"
							overlap="circular"
							badgeContent=" "
							variant="dot"
						>
							<Avatar
								alt={user?.instie ? 'Nish Mahadev' : 'Saloni Paryani'}
								src={
									user?.instie
										? 'https://public.saxonweber.com/nish64.jpeg'
										: 'https://public.saxonweber.com/saloni64.jpg'
								}
								sx={{ width: 48, height: 48, fontSize: 16 }}
							/>
						</Badge>
					</Stack>
					<Stack direction={'column'} spacing={0}>
						<Typography variant={'body1'} color="text.primary">
							{user?.instie ? 'Nish Mahadev' : 'Saloni Paryani'}
						</Typography>
						<Typography variant={'body2'} color="text.secondary">
							{user?.instie ? 'Institutional' : 'Personal'} Account Manager
						</Typography>
					</Stack>
				</Stack>
				<Box ml={'72px'} mt={'10px'}>
					<Button
						variant="contained"
						href="https://outlook.office.com/bookwithme/user/4085507ff0d24581bdd8d1fd9d85fe96@saxonweber.com?anonymous&ep=pcard"
						target={'_blank'}
						size="small"
						startIcon={<CalendarToday />}
					>
						book a call
					</Button>
				</Box>
			</HeaderPaper>
			<ChatBoxWrapper>
				<ChatBox messages={messages} setMessages={setMessages} id="user-chat-box" />
			</ChatBoxWrapper>
			<LinearProgress sx={{ visibility: isFileLoading ? 'visible' : 'hidden' }} />
			<FooterPaper>
				<ChatControls handleSendMessage={handleSendMessage} />
			</FooterPaper>
		</>
	);
};

export default ChatTab;
