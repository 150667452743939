import { toast } from 'react-toastify';
import { Button, Stack, Typography } from '@mui/material';

const VERSION_STORAGE_KEY = 'appVersion';
const VERSION_TIMESTAMP_KEY = 'appVersionTimestamp';
const CHECK_INTERVAL = 900000; // 15 minutes in milliseconds

const saveVersion = (newVersion: string) => {
	localStorage.setItem(VERSION_STORAGE_KEY, newVersion);
	localStorage.setItem(VERSION_TIMESTAMP_KEY, Date.now().toString());
};

const onPageReload = (newVersion: string) => {
	saveVersion(newVersion);
	window.location.reload();
};

const onFirstLoad = async () => {
	try {
		const response = await fetch('/data/version.json');
		const data = await response.json();
		saveVersion(data.version);
	} catch (error) {
		console.error('RunVersionCheck: No version on the server!');
	}
};

const checkVersion = async () => {
	try {
		const response = await fetch('/data/version.json');
		const data = await response.json();
		const currentVersion = localStorage.getItem(VERSION_STORAGE_KEY);
		const lastSavedTimestamp = localStorage.getItem(VERSION_TIMESTAMP_KEY);
		const lastSavedTime = lastSavedTimestamp ? parseInt(lastSavedTimestamp, 10) : 0;
		const now = Date.now();

		if (currentVersion !== data.version && now - lastSavedTime > CHECK_INTERVAL) {
			toast.info(
				<Stack spacing={2} direction="row" alignItems="center">
					<Typography>New version of the app is here.</Typography>
					<Button variant="contained" size="small" onClick={() => onPageReload(data.version)}>
						Refresh page
					</Button>
				</Stack>,
				{
					toastId: 'versionToast',
					position: 'top-center',
					autoClose: false,
					closeOnClick: false,
					theme: 'dark',
					style: {
						width: 440,
					},
				}
			);
		}
	} catch (error) {
		console.error('RunVersionCheck: No version on the server!');
	}
};

export const runVersionCheck = () => {
	onFirstLoad();

	// Then set up interval
	setInterval(checkVersion, CHECK_INTERVAL);
};
