import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ActivityFeedProvider, AuthProvider, LoaderProvider, ModalProvider, NotificationProvider } from './context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme } from './styles/theme';
import Main from './layout/Main';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license';
import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
	debug: true,
	track_pageview: true,
	persistence: 'localStorage',
});

LicenseInfo.setLicenseKey(
	'e282b94ba10ce51c13b700b6c159ac0aTz0xMDgzOTcsRT0xNzUzNTc0Mzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
);

const app = document.getElementById('root');

const root = createRoot(app);

root.render(
	<StrictMode>
		<BrowserRouter>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				theme="dark"
			/>
			<ThemeProvider theme={darkTheme}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<LoaderProvider>
						<AuthProvider>
							<ModalProvider>
								<NotificationProvider>
									<ActivityFeedProvider>
										<Main>
											<CssBaseline enableColorScheme />
											<AppRouter />
										</Main>
									</ActivityFeedProvider>
								</NotificationProvider>
							</ModalProvider>
						</AuthProvider>
					</LoaderProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</BrowserRouter>
	</StrictMode>
);

reportWebVitals();
