import React, { useMemo } from 'react';
import { useAuthContext } from '../../context';
import RetailAccreditationDialog from './components/RetailAccreditationDialog';
import InstiAccreditationDialog from './components/InstiAccreditationDialog';

const AccreditationQuestionnaireView: React.FC = () => {
	const { user } = useAuthContext();

	const isInstitutionalFirm = useMemo(() => {
		return user?.fields?.Type === 'An Institutional Firm';
	}, [user]);

	const isAccreditedInvestor = useMemo(() => {
		return user?.fields?.Type === 'An Accredited Investor';
	}, [user]);

	if (isInstitutionalFirm || isAccreditedInvestor) {
		return <InstiAccreditationDialog />;
	}

	return <RetailAccreditationDialog />;
};

export default AccreditationQuestionnaireView;
