import { Button, Fade, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { IRetailAccreditationQuestionnaireForm } from '../../formConstants';

const GoodStandingStep: React.FC = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { values, setFieldValue } = useFormikContext<IRetailAccreditationQuestionnaireForm>();

	return (
		<Fade in timeout={500}>
			<Stack direction={'column'} alignItems={'center'}>
				<Stack spacing={5} direction={'column'} alignItems={'center'}>
					<Typography variant={'h6'} textAlign={'center'}>
						Do you hold{' '}
						<Typography variant={'h6'} component={'span'} color={'primary.main'}>
							any
						</Typography>{' '}
						of the following financial certifications?
					</Typography>
					<Stack direction={'column'} spacing={1}>
						<Stack direction={'row'} spacing={2} alignItems={'center'}>
							<Check fontSize={'small'} color={'primary'} />
							<Typography>
								Series 7{' '}
								<Typography component={'span'} color={'text.secondary'}>
									(General securities representative license)
								</Typography>
							</Typography>
						</Stack>
						<Stack direction={'row'} spacing={2} alignItems={'center'}>
							<Check fontSize={'small'} color={'primary'} />
							<Typography>
								Series 65{' '}
								<Typography component={'span'} color={'text.secondary'}>
									(Investment adviser representative license)
								</Typography>
							</Typography>
						</Stack>
						<Stack direction={'row'} spacing={2} alignItems={'center'}>
							<Check fontSize={'small'} color={'primary'} />
							<Typography>
								Series 82{' '}
								<Typography component={'span'} color={'text.secondary'}>
									(Private securities offerings representative license)
								</Typography>
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={2} direction={'row'} width={isMobile ? '100%' : undefined}>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Check />}
							onClick={() => {
								setFieldValue('goodStanding', 'Yes');
								setFieldValue('step', values.step + 1);
							}}
							fullWidth={isMobile}
						>
							Yes
						</Button>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Close />}
							onClick={() => {
								setFieldValue('goodStanding', 'No');
								setFieldValue('step', values.step + 1);
							}}
							fullWidth={isMobile}
						>
							No
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Fade>
	);
};

export default GoodStandingStep;
