export interface IRetailAccreditationQuestionnaireForm {
	step: number;
	minIncome: 'Yes' | 'No';
	netWorth: 'Yes' | 'No';	
	goodStanding: 'Yes' | 'No';
}

export const initialValues: IRetailAccreditationQuestionnaireForm = {
	step: 0,
	minIncome: 'No',
	netWorth: 'No',
	goodStanding: 'No',
};
