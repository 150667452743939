import { Button, Fade, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import { IRetailAccreditationQuestionnaireForm } from '../../formConstants';

const MinIncomeStep: React.FC = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { values, setFieldValue } = useFormikContext<IRetailAccreditationQuestionnaireForm>();

	return (
		<Fade in timeout={500}>
			<Stack direction={'column'} alignItems={'center'}>
				<Stack spacing={5} direction={'column'} alignItems={'center'}>
					<Typography variant={'h6'} textAlign={'center'}>
						Does your individual annual income exceed $200,000, or does your joint annual income with a spouse or
						spousal equivalent exceed $300,000? AND/OR Does your net worth (excluding your primary residence) exceed $1
						million?
					</Typography>
					<Stack spacing={2} direction={'row'} width={isMobile ? '100%' : undefined}>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Check />}
							onClick={() => {
								setFieldValue('minIncome', 'Yes');
								setFieldValue('step', values.step + 1);
							}}
							fullWidth={isMobile}
						>
							Yes
						</Button>
						<Button
							variant={'outlined'}
							size={'large'}
							startIcon={<Close />}
							onClick={() => {
								setFieldValue('minIncome', 'No');
								setFieldValue('step', values.step + 2);
							}}
							fullWidth={isMobile}
						>
							No
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Fade>
	);
};

export default MinIncomeStep;
