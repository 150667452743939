import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { apiClient, endpoints } from '../utils';

interface NotificationContextType {
	intercomBadge?: number;
	dealChatsBadge?: number;
}

const defaultContextValue: NotificationContextType = {
	intercomBadge: 0,
	dealChatsBadge: 0,
};

const NotificationContext = createContext<NotificationContextType>(defaultContextValue);

export const NotificationProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const CHECK_INTERVAL = 600000; // 10 minutes in milliseconds
	const [intercomChats, setIntercomChats] = useState<Array<any>>([]);
	const [dealChats, setDealChats] = useState<Array<any>>([]);

	useEffect(() => {
		loadIntercomChats();
		loadDealChats();

		const interval = setInterval(() => {
			loadIntercomChats();
			loadDealChats();
		}, CHECK_INTERVAL);

		return () => clearInterval(interval);
	}, []);

	const loadIntercomChats = async () => {
		const res = await apiClient.post(endpoints.getChats, {
			page: 1,
			pageSize: 20,
			isNew: true,
		});
		const data = res.data.data;

		if (data) {
			setIntercomChats(data);
		}
	};

	const loadDealChats = async () => {
		const res = await apiClient.post(endpoints.getDealchats, {
			page: 1,
			pageSize: 30,
			isNew: true,
		});
		const data = res.data.data;

		if (data) {
			setDealChats(data);
		}
	};

	return (
		<NotificationContext.Provider
			value={{
				intercomBadge: intercomChats.length,
				dealChatsBadge: dealChats.length,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotificationContext = () => useContext(NotificationContext);
